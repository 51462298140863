<template>
  <div>
    <div v-if="isLoadError || isLoading" class="container">
      <PageLoading loadingText="Loading... Please wait..." v-if="isLoading"></PageLoading>
      <Page404 v-else :errorText="errMsg" :errorCode="errStatus"></Page404>
    </div>
    <div v-else class="container">
      <div class="lay-options-both">
        <div>
          <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
        </div>
        <div>
          <VEditButton class="eb-sec" @click="onAddProposalForm">Empty Application Form</VEditButton>
          <VEditButton class="eb-sec" @click="onImportProposalForm">Import Application Form</VEditButton>

          <!-- Temporarily disabled -->
          <!-- <VEditButton class="eb-sec" @click="openSetRestrictedEmailsModal" :disabled="true">Set Restricted Access Emails List</VEditButton> -->
         </div>
      </div>
      <div>
        <RestrictedListEmailsSetModal :clientId="clientId" ref="setRestrictedEmailsModal"></RestrictedListEmailsSetModal>
      </div>
      <hr />
      <div class="row text-center pt-3">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <VHeaderEdit :text="companyName" :allowEmpty="false" @text-updated="onClientNameUpdated"></VHeaderEdit>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div v-if="proposals.length > 0">
        <div class="list-group pt-5">
          <ProposalListItem
            v-for="proposal in activeProposals"
            :key="proposal.id"
            v-bind="proposal"
            @delete="onProposalDelete"
            @renewal="onProposalRenew"
            @activate="onProposalActivate"
            @draft="onProposalDraft"
            @freeze="onProposalFreeze"
            @download="onDownloadProposal"
            @rename="onProposalNameUpdated"
            @importAnswers="onImportAnswers"
            @toggleRestrict="openRestrictProposalModal"
          >
          </ProposalListItem>
        </div>
        <div v-if="frozenProposals.length > 0">
          <div class="row text-center pt-5">
            <div class="col-sm">
              <h5>Frozen Application</h5>
              <p>
                <small>These can no longer be updated and are not accessible by the client.</small>
              </p>
            </div>
          </div>
          <div class="list-group pt-1">
            <ProposalListItem
              v-for="proposal in frozenProposals"
              :key="proposal.id"
              v-bind="proposal"
              @download="onDownloadProposal"
              @defrost="onDefrost"
              @copy="onProposalRenew"
              @rename="onProposalNameUpdated"
            >
            </ProposalListItem>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <h5 style="margin-top: 5rem">No Application Forms</h5>
        <p><small>Maybe add one eh?</small></p>
        <VEditButton class="eb-sec mt-5" @click="onAddProposalForm">Empty Form</VEditButton>
        <VEditButton class="eb-sec mt-5" @click="onImportProposalForm">Import</VEditButton>
      </div>
      <hr style="margin-top: 5rem" />
      <div class="lay-options-right" style="margin-bottom: 1rem">
        <VEditButton class="eb-fade text-danger" @click="onClientDelete">Delete Client</VEditButton>
      </div>
    </div>
    <v-dialog />
  </div>
</template>

<script>
import ProposalListItem from '@broker/clientProposals/ui/ProposalListItem.vue';
import { publishedProposalDialog, deleteProposalDialog, deleteClientDialog } from '@broker/clientProposals/ui/proposalDialogs.js';
import RestrictedListEmailsSetModal from '@broker/clientProposals/ui/RestrictedListEmailsSetModal.vue';
import RestrictProposalModal from '@broker/clientProposals/ui/RestrictProposalModal.vue';

export default {
  components: {
    ProposalListItem,
    RestrictedListEmailsSetModal,
  },
  props: ['clientId'],
  data() {
    return {
      companyName: '',
      proposals: [],
      isLoadError: false,
      isLoading: true,
      errStatus: '',
      errMsg: '',
    };
  },
  created() {
    this.isLoading = true;
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  computed: {
    activeProposals() {
      return this.proposals.filter((x) => {
        return x.state === 'Active' || x.state === 'Draft' || x.state === 'Completed';
      });
    },
    frozenProposals() {
      return this.proposals.filter((x) => {
        return x.state === 'Frozen';
      });
    },
  },
  methods: {
    fetchData() {
      this.$api.company
        .getProposalsForClient(this.clientId)
        .then((res) => {
          this.companyName = res.data.name;
          this.proposals = res.data.proposals;
        })
        .catch((err) => {
          if (this.$debug) console.log('Error', err);
          this.errMsg = err.message;
          this.isLoadError = true;
          if (err.response && err.response.status) {
            this.errStatus = err.response.status;
            if (this.errStatus == '404') 
              this.errMsg = 'Client was not found';
          }else{
            this.errStatus = '404'
          }
        })
        .then(() => {
          // Always executed
          this.isLoading = false;
        });
    },
    onBack() {
      this.$router.push('/broker/clients');
    },
    onAddProposalForm() {
      this.$router.push({ path: `/broker/client/${this.clientId}/addForm` });
    },
    onImportProposalForm() {
      this.$router.push({ path: `/broker/client/${this.clientId}/importProposal` });
    },
    onProposalDelete(id) {
      deleteProposalDialog(this.$modal, id, () => this.fetchData());
    },
    onProposalRenew(id) {
      this.$router.push({ path: `/broker/proposal/${id}/renewal` });
    },
    onImportAnswers(id){
      this.$router.push({ path: `/broker/proposal/${id}/importAnswers` });
    },
    async onProposalActivate(id) {
      await this.$api.getProposalApi().postChangeState(id, 'Active');

      const proposal = this.activeProposals.filter((x) => x.id === id)[0];
      publishedProposalDialog(this.$modal, proposal.fullLink, proposal.clientLink, () => this.fetchData());
    },
    onProposalDraft(id) {
      this.changeState(id, 'Draft');
    },
    onProposalFreeze(id) {
      this.changeState(id, 'Frozen');
    },
    onDefrost(id) {
      this.changeState(id, 'Active');
    },
    changeState(id, newState) {
      this.$api.getProposalApi().postChangeState(id, newState).then(() => this.fetchData());
    },
    onDownloadProposal(id) {
      let proposalName = this.proposals.filter((x) => x.id === id)[0].name;
      this.$api.getProposalApi().download(id, proposalName, this.companyName);
    },
    // TODO: Should move this to a component that is just delete button and this.
    onClientDelete() {
      if (this.$debug) console.log('Deleting client');

      deleteClientDialog(this.$modal, this.$toast, this.companyName, this.clientId, () => {
        this.$router.push('/broker/clients');
      });
    },
    onClientNameUpdated(name) {
      if (this.$debug) console.log('Updating client name');

      this.$api.company.postEditCompany(this.clientId, name);
    },
    onProposalNameUpdated(item) {
      if (this.$debug) console.log('Updating propsal name', item);

      this.$api.getProposalApi().postRename(item.id, item.name);
    },
    openSetRestrictedEmailsModal() {
      if (this.$debug) console.log('openSetRestrictedEmailsModal');

      this.$refs.setRestrictedEmailsModal.showSetEmailsModal();
    },
    openRestrictProposalModal(id) {
      if (this.$debug) console.log('openRestrictProposalModal', id);

      const proposal = this.proposals.filter((x) => x.id === id)[0];
      const propDetails = { lockStatus: proposal.restricted, name: proposal.name, proposalId: proposal.id }
      this.$modal.show(
        RestrictProposalModal,
        propDetails,
        { height: 'auto',  scrollable:true,  classes:'modal-content' },
        { 'closed': this.fetchData}
      )
    }
  },
};
</script>

<style scoped>
.header {
  margin-top: 50px;
  margin-bottom: 20px;
}
.playwright-formlink {
}
</style>
