<template>
  <div id='app'>
    <header>
      <NavBar :environmentMenuBar='environmentMenuBar'></NavBar>
    </header>
    <main role='main'>
      <router-view/>
    </main>
  </div>
</template>

<script>
import '@s/css/main.css'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: { 
    ...mapGetters(['environmentMenuBar']),
  },
  created() {
    this.routeChange();
  },
  watch: {
    $route: 'routeChange'
  },
  methods: {
    routeChange(){
      this.setTitle();
    },
    setTitle(){
      if(this.environmentMenuBar === 'local-standalone'){
        document.title =  "DEV - Brokit"
      }
    },
  }
}
</script>

<style>
</style>
